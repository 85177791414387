var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.workInProgress)?_c('div',[_c('h1',[_vm._v("WORK IN PROGRESS")])]):_c('div',[_c('CCard',[_c('CCardHeader',[_c('h4',[_vm._v(" "+_vm._s("Last Generated: " + _vm.lastGenerated)+" "),_c('div',{staticClass:"float-right"},[_vm._v(" Latest "),_c('b',[_c('a',{on:{"click":function($event){return _vm.$router.push({ path: '/dashboard' })}}},[_vm._v("Changelog")])])])]),_c('security-help',{attrs:{"text":_vm.selectedtips,"selectedColumn":_vm.selectedColumn}})],1),_c('CCardBody',{staticClass:"Resizable-KPIs Resizable-KPIs-Overview"},[_c('CDataTable',{attrs:{"items":_vm.computedItems,"fields":_vm.fields,"items-per-page-select":"","items-per-page":10,"loading":_vm.isLoading,"column-filter":"","table-filter":"","sorter":"","hover":"","striped":"","small":"","pagination":"","clickableRows":""},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"ToAccounts",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",staticStyle:{"font-weight":"900"}},[(_vm.linkToAccounts(item))?_c('div',{on:{"click":function($event){return _vm.$router.push({
                  name: 'Accounts for for specific BE',
                  params: {
                    // BE: item.BE,
                    // BU: item.BU,
                    ORG: item.OrgId,
                  },
                })}}},[_vm._v(" show ")]):_vm._e()])]}},{key:"Score",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"py-2",staticStyle:{"font-weight":"900"},attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.Score)+" ")])]}},{key:"BE",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"Sticky-Column-KPI"},[_vm._v(" "+_vm._s(item.BE)+" ")])]}},{key:"CERTIFIEDREPORT",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(
                item.CERTIFIEDREPORT !== null &&
                item.CERTIFIEDREPORT.Value !== null
              )?_c('CButton',{attrs:{"color":item.CERTIFIEDREPORT.Value === 100 ? 'success' : 'danger',"height":"0.8rem","size":"sm"}},[_vm._v(_vm._s(item.CERTIFIEDREPORT.Value + "%"))]):_vm._e()],1)]}},{key:"MFA",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.MFA !== null)?_c('CButton',{attrs:{"color":item.MFA === 100 ? 'success' : 'danger',"height":"0.8rem","size":"sm"}},[_vm._v(_vm._s(item.MFA + "%"))]):_vm._e()],1)]}},{key:"CT",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.CT !== null)?_c('CButton',{attrs:{"color":item.CT === 100 ? 'success' : 'danger',"height":"0.8rem","size":"sm"}},[_vm._v(_vm._s(item.CT + "%"))]):_vm._e()],1)]}},{key:"TA",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.TA !== null)?_c('CButton',{attrs:{"color":item.TA === 100 ? 'success' : 'danger',"height":"0.8rem","size":"sm"}},[_vm._v(_vm._s(item.TA + "%"))]):_vm._e()],1)]}},{key:"IAM",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.IAM, 'IAM'),"toggle":_vm.toggle.IAM},on:{"show-hide":function($event){return _vm.toggleRows('IAM')}}})]}},{key:"IAM-SCORE",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.Score,"check":item.IAM.Score === 0}})]}},{key:"IAM-USER",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.User,"FormatValueExceptions":true,"check":item.IAM.User.Value === 0}})]}},{key:"IAM-AGE",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.Age,"FormatValueExceptions":false,"check":item.IAM.Age === 0}})]}},{key:"IAM-ADMIN",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.Admin,"FormatValueExceptions":false,"check":item.IAM.Admin === 0}})]}},{key:"IAM-SVCCONSOLE",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.SvcConsole,"FormatValueExceptions":true,"check":item.IAM.SvcConsole.Value === 0,"unit":"%"}})]}},{key:"IAM-NOBOUNDARY",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.NoBoundary,"FormatValueExceptions":true,"check":item.IAM.NoBoundary.Value === 0,"unit":"%"}})]}},{key:"S3",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.S3, 'S3'),"toggle":_vm.toggle.S3},on:{"show-hide":function($event){return _vm.toggleRows('S3')}}})]}},{key:"S3-Encrypted",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.Encrypted,"FormatValueExceptions":true,"check":item.S3.Encrypted.Value === 100,"unit":"%","score":_vm.getScore(item.S3, 'S3')}})]}},{key:"S3-EncryptionInTransit",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.EncryptionInTransit,"FormatValueExceptions":true,"check":item.S3.EncryptionInTransit.Value === 0,"score":_vm.getScore(item.S3, 'S3')}})]}},{key:"S3-Public",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.Public,"FormatValueExceptions":true,"check":item.S3.Public.Value === 0,"score":_vm.getScore(item.S3, 'S3')}})]}},{key:"S3-AccessPoint",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.AccessPoint.Public,"FormatValueExceptions":true,"check":item.S3.AccessPoint.Public.Value === 0,"score":_vm.getScore(item.S3, 'S3')}})]}},{key:"RDS",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.RDS, 'RDS'),"toggle":_vm.toggle.RDS},on:{"show-hide":function($event){return _vm.toggleRows('RDS')}}})]}},{key:"RDS-Encrypted",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.RDS.Encrypted,"FormatValueExceptions":true,"check":item.RDS.Encrypted.Value === 100,"unit":"%","score":_vm.getScore(item.RDS, 'RDS')}})]}},{key:"RDS-Public",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.RDS.Public,"FormatValueExceptions":true,"check":item.RDS.Public.Value === 0,"score":_vm.getScore(item.RDS, 'RDS')}})]}},{key:"EBS",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.EBS.Encrypted, 'ONE', 100),"toggle":_vm.toggle.EBS},on:{"show-hide":function($event){return _vm.toggleRows('EBS')}}})]}},{key:"EBS-Encrypted",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EBS.Encrypted,"FormatValueExceptions":true,"check":item.EBS.Encrypted.Value === 100,"unit":"%","score":_vm.getScore(item.EBS.Encrypted, 'ONE', 100)}})]}},{key:"EFS",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.EFS.Encrypted, 'ONE', 100),"toggle":_vm.toggle.EFS},on:{"show-hide":function($event){return _vm.toggleRows('EFS')}}})]}},{key:"EFS-Encrypted",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EFS.Encrypted,"FormatValueExceptions":true,"check":item.EFS.Encrypted.Value === 100,"unit":"%","score":_vm.getScore(item.EFS.Encrypted, 'ONE', 100)}})]}},{key:"FSX",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.FSX.Encrypted, 'ONE', 100),"toggle":_vm.toggle.FSX},on:{"show-hide":function($event){return _vm.toggleRows('FSX')}}})]}},{key:"FSX-Encrypted",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.FSX.Encrypted,"FormatValueExceptions":true,"check":item.FSX.Encrypted.Value === 100,"unit":"%","score":_vm.getScore(item.FSX.Encrypted, 'ONE', 100)}})]}},{key:"NatGateway",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.NatGateway, 'NatGateway', 0),"toggle":_vm.toggle.NatGateway},on:{"show-hide":function($event){return _vm.toggleRows('NatGateway')}}})]}},{key:"NatGateway-Total",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NatGateway.Total,"FormatValueExceptions":true,"check":item.NatGateway.Total.Value === 0,"score":_vm.getScore(item.NatGateway, 'NatGateway', 0),"isTotal":""}})]}},{key:"NatGateway-NetworkFirewallNotAttached",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NatGateway.NetworkFirewallNotAttached,"FormatValueExceptions":true,"check":item.NatGateway.NetworkFirewallNotAttached.Value === 0,"score":_vm.getScore(item.NatGateway, 'NatGateway', 0)}})]}},{key:"NatGateway-BadNetworkFirewall",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NatGateway.BadNetworkFirewall,"FormatValueExceptions":true,"check":item.NatGateway.BadNetworkFirewall.Value === 0,"score":_vm.getScore(item.NatGateway, 'NatGateway', 0)}})]}},{key:"EC2",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.EC2, 'EC2', 0),"toggle":_vm.toggle.EC2},on:{"show-hide":function($event){return _vm.toggleRows('EC2')}}})]}},{key:"EC2-SSHRDP_Public",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SSHRDP_Public,"FormatValueExceptions":true,"check":item.EC2.SSHRDP_Public.Value === 0,"score":_vm.getScore(item.EC2, 'EC2', 0)}})]}},{key:"EC2-SSHRDP_Private",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SSHRDP_Private,"FormatValueExceptions":true,"check":item.EC2.SSHRDP_Private.Value === 0,"score":_vm.getScore(item.EC2, 'EC2', 0),"isTotal":""}})]}},{key:"EC2-UNWANTEDPORTS_Public",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.UNWANTEDPORTS_Public,"FormatValueExceptions":true,"check":item.EC2.UNWANTEDPORTS_Public.Value === 0,"score":_vm.getScore(item.EC2, 'EC2', 0)}})]}},{key:"EC2-UNWANTEDPORTS_Private",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.UNWANTEDPORTS_Private,"FormatValueExceptions":true,"check":item.EC2.SSHRDP_Private.Value === 0,"score":_vm.getScore(item.EC2, 'EC2', 0),"isTotal":""}})]}},{key:"EC2-SG00_Public",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SG00_Public,"FormatValueExceptions":true,"check":item.EC2.SG00_Public.Value === 0,"score":_vm.getScore(item.EC2, 'EC2', 0)}})]}},{key:"EC2-SG00_Private",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SG00_Private,"FormatValueExceptions":true,"check":item.EC2.SG00_Private.Value === 0,"score":_vm.getScore(item.EC2, 'EC2', 0),"isTotal":""}})]}},{key:"EC2-BadInspector",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.BadInspector,"FormatValueExceptions":true,"check":item.EC2.BadInspector.Value === 0,"unit":"%","score":_vm.getScore(item.EC2, 'EC2', 0)}})]}},{key:"EC2-Public",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Public,"FormatValueExceptions":true,"check":item.EC2.Public.Value === 0,"score":_vm.getScore(item.EC2, 'EC2', 0)}})]}},{key:"EC2-Metadata-IMDSv2NotEnforced",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Metadata.IMDSv2NotEnforced,"FormatValueExceptions":true,"check":item.EC2.Metadata.IMDSv2NotEnforced.Value === 0,"unit":"%","score":_vm.getScore(item.EC2, 'EC2', 0)}})]}},{key:"EC2-SSMAgent",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SSMAgent,"FormatValueExceptions":true,"check":item.EC2.SSMAgent.Value === 0,"unit":"%","score":_vm.getScore(item.EC2, 'EC2', 0)}})]}},{key:"EC2-Permissions",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Permissions,"FormatValueExceptions":true,"check":item.EC2.Permissions.Value === 0,"unit":"%","score":_vm.getScore(item.EC2, 'EC2', 0)}})]}},{key:"Inspector",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.Inspector, 'Inspector', 0),"toggle":_vm.toggle.Inspector},on:{"show-hide":function($event){return _vm.toggleRows('Inspector')}}})]}},{key:"Inspector-Findings-Critical",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Inspector.Findings.Critical,"FormatValueExceptions":false,"check":item.Inspector.Findings.Critical === 0,"score":_vm.getScore(item.Inspector, 'Inspector', 0)}})]}},{key:"Inspector-Findings-High",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Inspector.Findings.High,"FormatValueExceptions":false,"check":item.Inspector.Findings.High === 0,"score":_vm.getScore(item.Inspector, 'Inspector', 0)}})]}},{key:"XLB",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.XLB.BadTLS, 'ONE', 0),"toggle":_vm.toggle.XLB},on:{"show-hide":function($event){return _vm.toggleRows('XLB')}}})]}},{key:"XLB-BadTLS",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.XLB.BadTLS,"FormatValueExceptions":true,"check":item.XLB.BadTLS.Value === 0,"score":_vm.getScore(item.XLB.BadTLS, 'ONE', 0)}})]}},{key:"Subnet",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.Subnet, 'Subnet', 0),"toggle":_vm.toggle.Subnet},on:{"show-hide":function($event){return _vm.toggleRows('Subnet')}}})]}},{key:"Subnet-DefaultNACL",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.DefaultNACL,"FormatValueExceptions":true,"check":item.Subnet.DefaultNACL.Value === 0,"unit":"%","score":_vm.getScore(item.Subnet, 'Subnet', 0)}})]}},{key:"Subnet-InternetFacing",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.InternetFacing,"FormatValueExceptions":true,"unit":"%","isTotal":""}})]}},{key:"Subnet-OpenPorts",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.OpenPorts,"FormatValueExceptions":true,"check":item.Subnet.OpenPorts.Value === 0,"unit":"%","score":_vm.getScore(item.Subnet, 'Subnet', 0)}})]}},{key:"Subnet-Public",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.Public.OpenPorts,"FormatValueExceptions":true,"check":item.Subnet.Public.OpenPorts.Value === 0,"unit":"%","score":_vm.getScore(item.Subnet, 'Subnet', 0)}})]}},{key:"Subnet-Private",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.Private.OpenPorts,"FormatValueExceptions":true,"check":item.Subnet.Private.OpenPorts.Value === 0,"unit":"%","score":_vm.getScore(item.Subnet, 'Subnet', 0)}})]}},{key:"CloudFront",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.CloudFront, 'CloudFront', 0),"toggle":_vm.toggle.CloudFront},on:{"show-hide":function($event){return _vm.toggleRows('CloudFront')}}})]}},{key:"CloudFront-BadTLS",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.CloudFront.BadTLS,"FormatValueExceptions":true,"check":item.CloudFront.BadTLS.Value === 0,"unit":"%","score":_vm.getScore(item.CloudFront, 'CloudFront', 0)}})]}},{key:"CloudFront-BadWAF",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.CloudFront.BadWAF,"FormatValueExceptions":true,"check":item.CloudFront.BadWAF.Value === 0,"unit":"%","score":_vm.getScore(item.CloudFront, 'CloudFront', 0)}})]}},{key:"PublishedResources",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.PublishedResources.BadShield, 'ONE', 0),"toggle":_vm.toggle.PublishedResources},on:{"show-hide":function($event){return _vm.toggleRows('PublishedResources')}}})]}},{key:"PublishedResources-BadShield",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PublishedResources.BadShield,"FormatValueExceptions":true,"check":item.PublishedResources.BadShield.Value === 0,"score":_vm.getScore(item.PublishedResources.BadShield, 'ONE', 0)}})]}},{key:"PubAppAndAPI",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.PubAppAndAPI, 'PubAppAndAPI', 0),"toggle":_vm.toggle.PubAppAndAPI},on:{"show-hide":function($event){return _vm.toggleRows('PubAppAndAPI')}}})]}},{key:"PubAppAndAPI-BadTLS",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubAppAndAPI.BadTLS,"FormatValueExceptions":true,"check":item.PubAppAndAPI.BadTLS.Value === 0,"unit":"%","score":_vm.getScore(item.PubAppAndAPI, 'PubAppAndAPI', 0)}})]}},{key:"PubAppAndAPI-BadWAF",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubAppAndAPI.BadWAF,"FormatValueExceptions":true,"check":item.PubAppAndAPI.BadWAF.Value === 0,"score":_vm.getScore(item.PubAppAndAPI, 'PubAppAndAPI', 0)}})]}},{key:"DYNAMODB",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.DYNAMODB.Encryption, 'ONE', 100),"toggle":_vm.toggle.DYNAMODB},on:{"show-hide":function($event){return _vm.toggleRows('DYNAMODB')}}})]}},{key:"DYNAMODB-Encryption",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.DYNAMODB.Encryption,"FormatValueExceptions":true,"check":item.DYNAMODB.Encryption.Value === 100,"unit":"%","score":_vm.getScore(item.DYNAMODB.Encryption, 'ONE', 100)}})]}},{key:"ELASTIC_SEARCH",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0),"toggle":_vm.toggle.ELASTIC_SEARCH},on:{"show-hide":function($event){return _vm.toggleRows('ELASTIC_SEARCH')}}})]}},{key:"ELASTIC_SEARCH-BadTLS",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTIC_SEARCH.BadTLS,"FormatValueExceptions":true,"check":item.ELASTIC_SEARCH.BadTLS.Value === 0,"score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0)}})]}},{key:"ELASTIC_SEARCH-EncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTIC_SEARCH.EncryptionAtRest,"FormatValueExceptions":true,"check":item.ELASTIC_SEARCH.EncryptionAtRest.Value === 100,"unit":"%","score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0)}})]}},{key:"ELASTIC_SEARCH-EncryptionInTransit",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTIC_SEARCH.EncryptionInTransit,"FormatValueExceptions":true,"check":item.ELASTIC_SEARCH.EncryptionInTransit.Value === 100,"unit":"%","score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0)}})]}},{key:"ELASTIC_SEARCH-NodeToNodeEncryption",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTIC_SEARCH.NodeToNodeEncryption,"FormatValueExceptions":true,"check":item.ELASTIC_SEARCH.NodeToNodeEncryption.Value === 100,"unit":"%","score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0)}})]}},{key:"ELASTICACHE",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.ELASTICACHE, 'ELASTICACHE', 0),"toggle":_vm.toggle.ELASTICACHE},on:{"show-hide":function($event){return _vm.toggleRows('ELASTICACHE')}}})]}},{key:"ELASTICACHE-EncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTICACHE.EncryptionAtRest,"FormatValueExceptions":true,"check":item.ELASTICACHE.EncryptionAtRest.Value === 100,"unit":"%","score":_vm.getScore(item.ELASTICACHE, 'ELASTICACHE', 0)}})]}},{key:"ELASTICACHE-EncryptionInTransit",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTICACHE.EncryptionInTransit,"FormatValueExceptions":true,"check":item.ELASTICACHE.EncryptionInTransit.Value === 100,"unit":"%","score":_vm.getScore(item.ELASTICACHE, 'ELASTICACHE', 0)}})]}},{key:"KINESIS",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.KINESIS.EncryptionAtRest, 'ONE', 100),"toggle":_vm.toggle.KINESIS},on:{"show-hide":function($event){return _vm.toggleRows('KINESIS')}}})]}},{key:"KINESIS-EncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.KINESIS.EncryptionAtRest,"FormatValueExceptions":true,"check":item.KINESIS.EncryptionAtRest.Value === 100,"unit":"%","score":_vm.getScore(item.KINESIS.EncryptionAtRest, 'ONE', 100)}})]}},{key:"KINESISFIREHOSE",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.KINESISFIREHOSE.EncryptionAtRest, 'ONE', 100),"toggle":_vm.toggle.KINESISFIREHOSE},on:{"show-hide":function($event){return _vm.toggleRows('KINESISFIREHOSE')}}})]}},{key:"KINESISFIREHOSE-EncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.KINESISFIREHOSE.EncryptionAtRest,"FormatValueExceptions":true,"check":item.KINESISFIREHOSE.EncryptionAtRest.Value === 100,"unit":"%","score":_vm.getScore(item.KINESISFIREHOSE.EncryptionAtRest, 'ONE', 100)}})]}},{key:"SQS",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.SQS.EncryptionAtRest, 'ONE', 100),"toggle":_vm.toggle.SQS},on:{"show-hide":function($event){return _vm.toggleRows('SQS')}}})]}},{key:"SQS-EncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SQS.EncryptionAtRest,"FormatValueExceptions":true,"check":item.SQS.EncryptionAtRest.Value === 100,"unit":"%","score":_vm.getScore(item.SQS.EncryptionAtRest, 'ONE', 100)}})]}},{key:"BACKUP",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.BACKUP.EncryptionAtRest, 'ONE', 100),"toggle":_vm.toggle.BACKUP},on:{"show-hide":function($event){return _vm.toggleRows('BACKUP')}}})]}},{key:"BACKUP-EncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.BACKUP.EncryptionAtRest,"FormatValueExceptions":true,"check":item.BACKUP.EncryptionAtRest.Value === 100,"unit":"%","score":_vm.getScore(item.BACKUP.EncryptionAtRest, 'ONE', 100)}})]}},{key:"XRAY",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.XRAY.Encryption, 'ONE', 100),"toggle":_vm.toggle.XRAY},on:{"show-hide":function($event){return _vm.toggleRows('XRAY')}}})]}},{key:"XRAY-Encryption",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.XRAY.Encryption,"FormatValueExceptions":true,"check":item.XRAY.Encryption.Value === 100,"unit":"%","score":_vm.getScore(item.XRAY.Encryption, 'ONE', 100)}})]}},{key:"REDSHIFT",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.REDSHIFT, 'REDSHIFT', 100),"toggle":_vm.toggle.REDSHIFT},on:{"show-hide":function($event){return _vm.toggleRows('REDSHIFT')}}})]}},{key:"REDSHIFT-EncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.REDSHIFT.EncryptionAtRest,"FormatValueExceptions":true,"check":item.REDSHIFT.EncryptionAtRest.Value === 100,"unit":"%","score":_vm.getScore(item.REDSHIFT, 'REDSHIFT', 100)}})]}},{key:"REDSHIFT-Public",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.REDSHIFT.Public,"FormatValueExceptions":true,"check":item.REDSHIFT.Public.Value === 0,"score":_vm.getScore(item.REDSHIFT, 'REDSHIFT', 100)}})]}},{key:"SAGEMAKER_NOTEBOOKINSTANCE",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(
                item.SAGEMAKER_NOTEBOOKINSTANCE,
                'SAGEMAKER_NOTEBOOKINSTANCE',
                100
              ),"toggle":_vm.toggle.SAGEMAKER_NOTEBOOKINSTANCE},on:{"show-hide":function($event){return _vm.toggleRows('SAGEMAKER_NOTEBOOKINSTANCE')}}})]}},{key:"SAGEMAKER_NOTEBOOKINSTANCE-DirectInternetAccess",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_NOTEBOOKINSTANCE.DirectInternetAccess,"FormatValueExceptions":true,"check":item.SAGEMAKER_NOTEBOOKINSTANCE.DirectInternetAccess.Value === 0,"unit":"%","score":_vm.getScore(
                item.SAGEMAKER_NOTEBOOKINSTANCE,
                'SAGEMAKER_NOTEBOOKINSTANCE',
                100
              )}})]}},{key:"SAGEMAKER_NOTEBOOKINSTANCE-RootAccess",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_NOTEBOOKINSTANCE.RootAccess,"FormatValueExceptions":true,"check":item.SAGEMAKER_NOTEBOOKINSTANCE.RootAccess.Value === 0,"unit":"%","score":_vm.getScore(
                item.SAGEMAKER_NOTEBOOKINSTANCE,
                'SAGEMAKER_NOTEBOOKINSTANCE',
                100
              )}})]}},{key:"SAGEMAKER_TRAININGJOB",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.SAGEMAKER_TRAININGJOB, 'SAGEMAKER_TRAININGJOB', 0),"toggle":_vm.toggle.SAGEMAKER_TRAININGJOB},on:{"show-hide":function($event){return _vm.toggleRows('SAGEMAKER_TRAININGJOB')}}})]}},{key:"SAGEMAKER_TRAININGJOB-EnableInterContainerTrafficEncryption",fn:function(ref){
          var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_TRAININGJOB.EnableInterContainerTrafficEncryption,"FormatValueExceptions":true,"check":item.SAGEMAKER_TRAININGJOB.EnableInterContainerTrafficEncryption
                .Value === 100,"unit":"%","score":_vm.getScore(item.SAGEMAKER_TRAININGJOB, 'SAGEMAKER_TRAININGJOB', 0)}})]}},{key:"SAGEMAKER_TRAININGJOB-NetworkIsolation",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_TRAININGJOB.NetworkIsolation,"FormatValueExceptions":true,"check":item.SAGEMAKER_TRAININGJOB.NetworkIsolation.Value === 100,"unit":"%","score":_vm.getScore(item.SAGEMAKER_TRAININGJOB, 'SAGEMAKER_TRAININGJOB', 0)}})]}},{key:"WORKSPACES",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.WORKSPACES, 'WORKSPACES', 0),"toggle":_vm.toggle.WORKSPACES},on:{"show-hide":function($event){return _vm.toggleRows('WORKSPACES')}}})]}},{key:"WORKSPACES-EncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.WORKSPACES.EncryptionAtRest,"FormatValueExceptions":true,"check":item.WORKSPACES.EncryptionAtRest.Value === 100,"unit":"%","score":_vm.getScore(item.WORKSPACES, 'WORKSPACES', 0)}})]}},{key:"WORKSPACES-EncryptionRoot",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.WORKSPACES.EncryptionRoot,"FormatValueExceptions":true,"check":item.WORKSPACES.EncryptionRoot.Value === 100,"unit":"%","score":_vm.getScore(item.WORKSPACES, 'WORKSPACES', 0)}})]}},{key:"WORKSPACES-EncryptionUser",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.WORKSPACES.EncryptionUser,"FormatValueExceptions":true,"check":item.WORKSPACES.EncryptionUser.Value === 100,"unit":"%","score":_vm.getScore(item.WORKSPACES, 'WORKSPACES', 0)}})]}},{key:"GLUE",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.GLUE, 'GLUE', 0),"toggle":_vm.toggle.GLUE},on:{"show-hide":function($event){return _vm.toggleRows('GLUE')}}})]}},{key:"GLUE-EncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.GLUE.EncryptionAtRest,"FormatValueExceptions":true,"check":item.GLUE.EncryptionAtRest.Value === 100,"unit":"%","score":_vm.getScore(item.GLUE, 'GLUE', 0)}})]}},{key:"GLUE-EncryptionInTransit",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.GLUE.EncryptionInTransit,"FormatValueExceptions":true,"check":item.GLUE.EncryptionInTransit.Value === 100,"unit":"%","score":_vm.getScore(item.GLUE, 'GLUE', 0)}})]}},{key:"GLUE-ConnectionPasswordEncryption",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.GLUE.ConnectionPasswordEncryption,"FormatValueExceptions":true,"check":item.GLUE.ConnectionPasswordEncryption.Value === 100,"unit":"%","score":_vm.getScore(item.GLUE, 'GLUE', 0)}})]}},{key:"STORAGEGATEWAY",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0),"toggle":_vm.toggle.STORAGEGATEWAY},on:{"show-hide":function($event){return _vm.toggleRows('STORAGEGATEWAY')}}})]}},{key:"STORAGEGATEWAY-VOL-EncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.EncryptionAtRest,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.EncryptionAtRest
                .Value === 100,"unit":"%","score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)}})]}},{key:"STORAGEGATEWAY-TAPE-EncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.EncryptionAtRest,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.EncryptionAtRest
                .Value === 100,"unit":"%","score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)}})]}},{key:"STORAGEGATEWAY-SHARE-EncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.EncryptionAtRest,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.EncryptionAtRest
                .Value === 100,"unit":"%","score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)}})]}},{key:"STORAGEGATEWAY-SHARE-EncryptionInTransit",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.EncryptionInTransit,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.EncryptionInTransit
                .Value === 100,"unit":"%","score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)}})]}},{key:"STORAGEGATEWAY-SHARE-GuestPassword",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.GuestPassword,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.GuestPassword
                .Value === 100,"unit":"%","score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)}})]}},{key:"VPC",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.VPC, 'VPC', 100),"toggle":_vm.toggle.VPC},on:{"show-hide":function($event){return _vm.toggleRows('VPC')}}})]}},{key:"VPC-Clean",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.VPC.Clean,"FormatValueExceptions":true,"check":item.VPC.Clean.Value === 100,"unit":"%","score":_vm.getScore(item.VPC, 'VPC', 100)}})]}},{key:"VPC-CompliantIP",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.VPC.CompliantIP,"FormatValueExceptions":true,"check":item.VPC.CompliantIP.Value === 100,"unit":"%","score":_vm.getScore(item.VPC, 'VPC', 100)}})]}},{key:"NETWORKFIREWALL",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL', 100),"toggle":_vm.toggle.NETWORKFIREWALL},on:{"show-hide":function($event){return _vm.toggleRows('NETWORKFIREWALL')}}})]}},{key:"NETWORKFIREWALL-Logging",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NETWORKFIREWALL.Logging,"FormatValueExceptions":true,"check":item.NETWORKFIREWALL.Logging.Value === 100,"unit":"%","score":_vm.getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL', 100)}})]}},{key:"NETWORKFIREWALL-DenyAnyAny",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NETWORKFIREWALL.DenyAnyAny,"FormatValueExceptions":true,"check":item.NETWORKFIREWALL.DenyAnyAny.Value === 100,"unit":"%","score":_vm.getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL', 100)}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }