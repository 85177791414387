<template>
  <div v-if="workInProgress">
    <h1>WORK IN PROGRESS</h1>
  </div>
  <div v-else>
    <CCard>
      <CCardHeader>
        <h4>
          {{ "Last Generated: " + lastGenerated }}
          <div class="float-right">
            Latest
            <b
              ><a @click="$router.push({ path: '/dashboard' })">Changelog</a></b
            >
          </div>
        </h4>
        <security-help :text="selectedtips" :selectedColumn="selectedColumn">
        </security-help>
      </CCardHeader>
      <CCardBody class="Resizable-KPIs Resizable-KPIs-Overview">
        <CDataTable
          :items="computedItems"
          :fields="fields"
          items-per-page-select
          :items-per-page="10"
          :loading="isLoading"
          column-filter
          table-filter
          sorter
          hover
          striped
          small
          pagination
          clickableRows
          @row-clicked="rowClicked"
        >
          <template #ToAccounts="{ item }">
            <td class="py-2" style="font-weight: 900">
              <div
                v-if="linkToAccounts(item)"
                @click="
                  $router.push({
                    name: 'Accounts for for specific BE',
                    params: {
                      // BE: item.BE,
                      // BU: item.BU,
                      ORG: item.OrgId,
                    },
                  })
                "
              >
                show
              </div>
            </td>
          </template>
          <template #Score="{ item }">
            <td class="py-2" style="font-weight: 900" color="primary">
              {{ item.Score }}
            </td>
          </template>
          <template #BE="{ item }">
            <td class="Sticky-Column-KPI">
              {{ item.BE }}
            </td>
          </template>
          <template #CERTIFIEDREPORT="{ item }">
            <td class="py-2">
              <CButton
                v-if="
                  item.CERTIFIEDREPORT !== null &&
                  item.CERTIFIEDREPORT.Value !== null
                "
                :color="
                  item.CERTIFIEDREPORT.Value === 100 ? 'success' : 'danger'
                "
                height="0.8rem"
                size="sm"
                >{{ item.CERTIFIEDREPORT.Value + "%" }}</CButton
              >
            </td>
          </template>
          <template #MFA="{ item }">
            <td class="py-2">
              <CButton
                v-if="item.MFA !== null"
                :color="item.MFA === 100 ? 'success' : 'danger'"
                height="0.8rem"
                size="sm"
                >{{ item.MFA + "%" }}</CButton
              >
            </td>
          </template>
          <template #CT="{ item }">
            <td class="py-2">
              <CButton
                v-if="item.CT !== null"
                :color="item.CT === 100 ? 'success' : 'danger'"
                height="0.8rem"
                size="sm"
                >{{ item.CT + "%" }}</CButton
              >
            </td>
          </template>
          <template #TA="{ item }">
            <td class="py-2">
              <CButton
                v-if="item.TA !== null"
                :color="item.TA === 100 ? 'success' : 'danger'"
                height="0.8rem"
                size="sm"
                >{{ item.TA + "%" }}</CButton
              >
            </td>
          </template>
          <template #IAM="{ item }">
            <show-score
              :score="getScore(item.IAM, 'IAM')"
              @show-hide="toggleRows('IAM')"
              :toggle="toggle.IAM"
            ></show-score>
          </template>
          <template #IAM-SCORE="{ item }">
            <show-data
              :data="item.IAM.Score"
              :check="item.IAM.Score === 0"
            ></show-data>
          </template>
          <template #IAM-USER="{ item }">
            <show-data
              :data="item.IAM.User"
              :FormatValueExceptions="true"
              :check="item.IAM.User.Value === 0"
            ></show-data>
          </template>
          <template #IAM-AGE="{ item }">
            <show-data
              :data="item.IAM.Age"
              :FormatValueExceptions="false"
              :check="item.IAM.Age === 0"
            ></show-data>
          </template>
          <template #IAM-ADMIN="{ item }">
            <show-data
              :data="item.IAM.Admin"
              :FormatValueExceptions="false"
              :check="item.IAM.Admin === 0"
            ></show-data>
          </template>
          <template #IAM-SVCCONSOLE="{ item }">
            <show-data
              :data="item.IAM.SvcConsole"
              :FormatValueExceptions="true"
              :check="item.IAM.SvcConsole.Value === 0"
              unit="%"
            ></show-data>
          </template>
          <template #IAM-NOBOUNDARY="{ item }">
            <show-data
              :data="item.IAM.NoBoundary"
              :FormatValueExceptions="true"
              :check="item.IAM.NoBoundary.Value === 0"
              unit="%"
            ></show-data>
          </template>
          <template #S3="{ item }">
            <show-score
              :score="getScore(item.S3, 'S3')"
              @show-hide="toggleRows('S3')"
              :toggle="toggle.S3"
            ></show-score>
          </template>
          <template #S3-Encrypted="{ item }">
            <show-data
              :data="item.S3.Encrypted"
              :FormatValueExceptions="true"
              :check="item.S3.Encrypted.Value === 100"
              unit="%"
              :score="getScore(item.S3, 'S3')"
            ></show-data>
          </template>
          <template #S3-EncryptionInTransit="{ item }">
            <show-data
              :data="item.S3.EncryptionInTransit"
              :FormatValueExceptions="true"
              :check="item.S3.EncryptionInTransit.Value === 0"
              :score="getScore(item.S3, 'S3')"
            ></show-data>
          </template>
          <template #S3-Public="{ item }">
            <show-data
              :data="item.S3.Public"
              :FormatValueExceptions="true"
              :check="item.S3.Public.Value === 0"
              :score="getScore(item.S3, 'S3')"
            ></show-data>
          </template>
          <template #S3-AccessPoint="{ item }">
            <show-data
              :data="item.S3.AccessPoint.Public"
              :FormatValueExceptions="true"
              :check="item.S3.AccessPoint.Public.Value === 0"
              :score="getScore(item.S3, 'S3')"
            ></show-data>
          </template>
          <template #RDS="{ item }">
            <show-score
              :score="getScore(item.RDS, 'RDS')"
              @show-hide="toggleRows('RDS')"
              :toggle="toggle.RDS"
            ></show-score>
          </template>
          <template #RDS-Encrypted="{ item }">
            <show-data
              :data="item.RDS.Encrypted"
              :FormatValueExceptions="true"
              :check="item.RDS.Encrypted.Value === 100"
              unit="%"
              :score="getScore(item.RDS, 'RDS')"
            ></show-data>
          </template>
          <template #RDS-Public="{ item }">
            <show-data
              :data="item.RDS.Public"
              :FormatValueExceptions="true"
              :check="item.RDS.Public.Value === 0"
              :score="getScore(item.RDS, 'RDS')"
            ></show-data>
          </template>
          <template #EBS="{ item }">
            <show-score
              :score="getScore(item.EBS.Encrypted, 'ONE', 100)"
              @show-hide="toggleRows('EBS')"
              :toggle="toggle.EBS"
            ></show-score>
          </template>
          <template #EBS-Encrypted="{ item }">
            <show-data
              :data="item.EBS.Encrypted"
              :FormatValueExceptions="true"
              :check="item.EBS.Encrypted.Value === 100"
              unit="%"
              :score="getScore(item.EBS.Encrypted, 'ONE', 100)"
            ></show-data>
          </template>
          <template #EFS="{ item }">
            <show-score
              :score="getScore(item.EFS.Encrypted, 'ONE', 100)"
              @show-hide="toggleRows('EFS')"
              :toggle="toggle.EFS"
            ></show-score>
          </template>
          <template #EFS-Encrypted="{ item }">
            <show-data
              :data="item.EFS.Encrypted"
              :FormatValueExceptions="true"
              :check="item.EFS.Encrypted.Value === 100"
              unit="%"
              :score="getScore(item.EFS.Encrypted, 'ONE', 100)"
            ></show-data>
          </template>
          <template #FSX="{ item }">
            <show-score
              :score="getScore(item.FSX.Encrypted, 'ONE', 100)"
              @show-hide="toggleRows('FSX')"
              :toggle="toggle.FSX"
            ></show-score>
          </template>
          <template #FSX-Encrypted="{ item }">
            <show-data
              :data="item.FSX.Encrypted"
              :FormatValueExceptions="true"
              :check="item.FSX.Encrypted.Value === 100"
              unit="%"
              :score="getScore(item.FSX.Encrypted, 'ONE', 100)"
            ></show-data>
          </template>
          <template #NatGateway="{ item }">
            <show-score
              :score="getScore(item.NatGateway, 'NatGateway', 0)"
              @show-hide="toggleRows('NatGateway')"
              :toggle="toggle.NatGateway"
            ></show-score>
          </template>
          <template #NatGateway-Total="{ item }">
            <show-data
              :data="item.NatGateway.Total"
              :FormatValueExceptions="true"
              :check="item.NatGateway.Total.Value === 0"
              :score="getScore(item.NatGateway, 'NatGateway', 0)"
              isTotal
            ></show-data>
          </template>
          <template #NatGateway-NetworkFirewallNotAttached="{ item }">
            <show-data
              :data="item.NatGateway.NetworkFirewallNotAttached"
              :FormatValueExceptions="true"
              :check="item.NatGateway.NetworkFirewallNotAttached.Value === 0"
              :score="getScore(item.NatGateway, 'NatGateway', 0)"
            ></show-data>
          </template>
          <template #NatGateway-BadNetworkFirewall="{ item }">
            <show-data
              :data="item.NatGateway.BadNetworkFirewall"
              :FormatValueExceptions="true"
              :check="item.NatGateway.BadNetworkFirewall.Value === 0"
              :score="getScore(item.NatGateway, 'NatGateway', 0)"
            ></show-data>
          </template>
          <template #EC2="{ item }">
            <show-score
              :score="getScore(item.EC2, 'EC2', 0)"
              @show-hide="toggleRows('EC2')"
              :toggle="toggle.EC2"
            ></show-score>
          </template>
          <template #EC2-SSHRDP_Public="{ item }">
            <show-data
              :data="item.EC2.SSHRDP_Public"
              :FormatValueExceptions="true"
              :check="item.EC2.SSHRDP_Public.Value === 0"
              :score="getScore(item.EC2, 'EC2', 0)"
            ></show-data>
          </template>
          <template #EC2-SSHRDP_Private="{ item }">
            <show-data
              :data="item.EC2.SSHRDP_Private"
              :FormatValueExceptions="true"
              :check="item.EC2.SSHRDP_Private.Value === 0"
              :score="getScore(item.EC2, 'EC2', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-UNWANTEDPORTS_Public="{ item }">
            <show-data
              :data="item.EC2.UNWANTEDPORTS_Public"
              :FormatValueExceptions="true"
              :check="item.EC2.UNWANTEDPORTS_Public.Value === 0"
              :score="getScore(item.EC2, 'EC2', 0)"
            ></show-data>
          </template>
          <template #EC2-UNWANTEDPORTS_Private="{ item }">
            <show-data
              :data="item.EC2.UNWANTEDPORTS_Private"
              :FormatValueExceptions="true"
              :check="item.EC2.SSHRDP_Private.Value === 0"
              :score="getScore(item.EC2, 'EC2', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-SG00_Public="{ item }">
            <show-data
              :data="item.EC2.SG00_Public"
              :FormatValueExceptions="true"
              :check="item.EC2.SG00_Public.Value === 0"
              :score="getScore(item.EC2, 'EC2', 0)"
            ></show-data>
          </template>
          <template #EC2-SG00_Private="{ item }">
            <show-data
              :data="item.EC2.SG00_Private"
              :FormatValueExceptions="true"
              :check="item.EC2.SG00_Private.Value === 0"
              :score="getScore(item.EC2, 'EC2', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-BadInspector="{ item }">
            <show-data
              :data="item.EC2.BadInspector"
              :FormatValueExceptions="true"
              :check="item.EC2.BadInspector.Value === 0"
              unit="%"
              :score="getScore(item.EC2, 'EC2', 0)"
            ></show-data>
          </template>
          <template #EC2-Public="{ item }">
            <show-data
              :data="item.EC2.Public"
              :FormatValueExceptions="true"
              :check="item.EC2.Public.Value === 0"
              :score="getScore(item.EC2, 'EC2', 0)"
            ></show-data>
          </template>
          <template #EC2-Metadata-IMDSv2NotEnforced="{ item }">
            <show-data
              :data="item.EC2.Metadata.IMDSv2NotEnforced"
              :FormatValueExceptions="true"
              :check="item.EC2.Metadata.IMDSv2NotEnforced.Value === 0"
              unit="%"
              :score="getScore(item.EC2, 'EC2', 0)"
            ></show-data>
          </template>
          <template #EC2-SSMAgent="{ item }">
            <show-data
              :data="item.EC2.SSMAgent"
              :FormatValueExceptions="true"
              :check="item.EC2.SSMAgent.Value === 0"
              unit="%"
              :score="getScore(item.EC2, 'EC2', 0)"
            ></show-data>
          </template>
          <template #EC2-Permissions="{ item }">
            <show-data
              :data="item.EC2.Permissions"
              :FormatValueExceptions="true"
              :check="item.EC2.Permissions.Value === 0"
              unit="%"
              :score="getScore(item.EC2, 'EC2', 0)"
            ></show-data>
          </template>
          <template #Inspector="{ item }">
            <show-score
              :score="getScore(item.Inspector, 'Inspector', 0)"
              @show-hide="toggleRows('Inspector')"
              :toggle="toggle.Inspector"
            ></show-score>
          </template>
          <template #Inspector-Findings-Critical="{ item }">
            <show-data
              :data="item.Inspector.Findings.Critical"
              :FormatValueExceptions="false"
              :check="item.Inspector.Findings.Critical === 0"
              :score="getScore(item.Inspector, 'Inspector', 0)"
            ></show-data>
          </template>
          <template #Inspector-Findings-High="{ item }">
            <show-data
              :data="item.Inspector.Findings.High"
              :FormatValueExceptions="false"
              :check="item.Inspector.Findings.High === 0"
              :score="getScore(item.Inspector, 'Inspector', 0)"
            ></show-data>
          </template>
          <template #XLB="{ item }">
            <show-score
              :score="getScore(item.XLB.BadTLS, 'ONE', 0)"
              @show-hide="toggleRows('XLB')"
              :toggle="toggle.XLB"
            ></show-score>
          </template>
          <template #XLB-BadTLS="{ item }">
            <show-data
              :data="item.XLB.BadTLS"
              :FormatValueExceptions="true"
              :check="item.XLB.BadTLS.Value === 0"
              :score="getScore(item.XLB.BadTLS, 'ONE', 0)"
            ></show-data>
          </template>
          <template #Subnet="{ item }">
            <show-score
              :score="getScore(item.Subnet, 'Subnet', 0)"
              :toggle="toggle.Subnet"
              @show-hide="toggleRows('Subnet')"
            ></show-score>
          </template>
          <template #Subnet-DefaultNACL="{ item }">
            <show-data
              :data="item.Subnet.DefaultNACL"
              :FormatValueExceptions="true"
              :check="item.Subnet.DefaultNACL.Value === 0"
              unit="%"
              :score="getScore(item.Subnet, 'Subnet', 0)"
            ></show-data>
          </template>
          <template #Subnet-InternetFacing="{ item }">
            <show-data
              :data="item.Subnet.InternetFacing"
              :FormatValueExceptions="true"
              unit="%"
              isTotal
            ></show-data>
          </template>
          <template #Subnet-OpenPorts="{ item }">
            <show-data
              :data="item.Subnet.OpenPorts"
              :FormatValueExceptions="true"
              :check="item.Subnet.OpenPorts.Value === 0"
              unit="%"
              :score="getScore(item.Subnet, 'Subnet', 0)"
            ></show-data>
          </template>
          <template #Subnet-Public="{ item }">
            <show-data
              :data="item.Subnet.Public.OpenPorts"
              :FormatValueExceptions="true"
              :check="item.Subnet.Public.OpenPorts.Value === 0"
              unit="%"
              :score="getScore(item.Subnet, 'Subnet', 0)"
            ></show-data>
          </template>
          <template #Subnet-Private="{ item }">
            <show-data
              :data="item.Subnet.Private.OpenPorts"
              :FormatValueExceptions="true"
              :check="item.Subnet.Private.OpenPorts.Value === 0"
              unit="%"
              :score="getScore(item.Subnet, 'Subnet', 0)"
            ></show-data>
          </template>
          <template #CloudFront="{ item }">
            <show-score
              :score="getScore(item.CloudFront, 'CloudFront', 0)"
              @show-hide="toggleRows('CloudFront')"
              :toggle="toggle.CloudFront"
            ></show-score>
          </template>
          <template #CloudFront-BadTLS="{ item }">
            <show-data
              :data="item.CloudFront.BadTLS"
              :FormatValueExceptions="true"
              :check="item.CloudFront.BadTLS.Value === 0"
              unit="%"
              :score="getScore(item.CloudFront, 'CloudFront', 0)"
            ></show-data>
          </template>
          <template #CloudFront-BadWAF="{ item }">
            <show-data
              :data="item.CloudFront.BadWAF"
              :FormatValueExceptions="true"
              :check="item.CloudFront.BadWAF.Value === 0"
              unit="%"
              :score="getScore(item.CloudFront, 'CloudFront', 0)"
            ></show-data>
          </template>
          <template #PublishedResources="{ item }">
            <show-score
              :score="getScore(item.PublishedResources.BadShield, 'ONE', 0)"
              @show-hide="toggleRows('PublishedResources')"
              :toggle="toggle.PublishedResources"
            ></show-score>
          </template>
          <template #PublishedResources-BadShield="{ item }">
            <show-data
              :data="item.PublishedResources.BadShield"
              :FormatValueExceptions="true"
              :check="item.PublishedResources.BadShield.Value === 0"
              :score="getScore(item.PublishedResources.BadShield, 'ONE', 0)"
            ></show-data>
          </template>
          <template #PubAppAndAPI="{ item }">
            <show-score
              :score="getScore(item.PubAppAndAPI, 'PubAppAndAPI', 0)"
              @show-hide="toggleRows('PubAppAndAPI')"
              :toggle="toggle.PubAppAndAPI"
            ></show-score>
          </template>
          <template #PubAppAndAPI-BadTLS="{ item }">
            <show-data
              :data="item.PubAppAndAPI.BadTLS"
              :FormatValueExceptions="true"
              :check="item.PubAppAndAPI.BadTLS.Value === 0"
              unit="%"
              :score="getScore(item.PubAppAndAPI, 'PubAppAndAPI', 0)"
            ></show-data>
          </template>
          <template #PubAppAndAPI-BadWAF="{ item }">
            <show-data
              :data="item.PubAppAndAPI.BadWAF"
              :FormatValueExceptions="true"
              :check="item.PubAppAndAPI.BadWAF.Value === 0"
              :score="getScore(item.PubAppAndAPI, 'PubAppAndAPI', 0)"
            ></show-data>
          </template>
          <template #DYNAMODB="{ item }">
            <show-score
              :score="getScore(item.DYNAMODB.Encryption, 'ONE', 100)"
              @show-hide="toggleRows('DYNAMODB')"
              :toggle="toggle.DYNAMODB"
            ></show-score>
          </template>
          <template #DYNAMODB-Encryption="{ item }">
            <show-data
              :data="item.DYNAMODB.Encryption"
              :FormatValueExceptions="true"
              :check="item.DYNAMODB.Encryption.Value === 100"
              unit="%"
              :score="getScore(item.DYNAMODB.Encryption, 'ONE', 100)"
            ></show-data>
          </template>
          <template #ELASTIC_SEARCH="{ item }">
            <show-score
              :score="getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0)"
              :toggle="toggle.ELASTIC_SEARCH"
              @show-hide="toggleRows('ELASTIC_SEARCH')"
            ></show-score>
          </template>
          <template #ELASTIC_SEARCH-BadTLS="{ item }">
            <show-data
              :data="item.ELASTIC_SEARCH.BadTLS"
              :FormatValueExceptions="true"
              :check="item.ELASTIC_SEARCH.BadTLS.Value === 0"
              :score="getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0)"
            ></show-data>
          </template>
          <template #ELASTIC_SEARCH-EncryptionAtRest="{ item }">
            <show-data
              :data="item.ELASTIC_SEARCH.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.ELASTIC_SEARCH.EncryptionAtRest.Value === 100"
              unit="%"
              :score="getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0)"
            ></show-data>
          </template>
          <template #ELASTIC_SEARCH-EncryptionInTransit="{ item }">
            <show-data
              :data="item.ELASTIC_SEARCH.EncryptionInTransit"
              :FormatValueExceptions="true"
              :check="item.ELASTIC_SEARCH.EncryptionInTransit.Value === 100"
              unit="%"
              :score="getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0)"
            ></show-data>
          </template>
          <template #ELASTIC_SEARCH-NodeToNodeEncryption="{ item }">
            <show-data
              :data="item.ELASTIC_SEARCH.NodeToNodeEncryption"
              :FormatValueExceptions="true"
              :check="item.ELASTIC_SEARCH.NodeToNodeEncryption.Value === 100"
              unit="%"
              :score="getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH', 0)"
            ></show-data>
          </template>
          <template #ELASTICACHE="{ item }">
            <show-score
              :score="getScore(item.ELASTICACHE, 'ELASTICACHE', 0)"
              :toggle="toggle.ELASTICACHE"
              @show-hide="toggleRows('ELASTICACHE')"
            ></show-score>
          </template>
          <template #ELASTICACHE-EncryptionAtRest="{ item }">
            <show-data
              :data="item.ELASTICACHE.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.ELASTICACHE.EncryptionAtRest.Value === 100"
              unit="%"
              :score="getScore(item.ELASTICACHE, 'ELASTICACHE', 0)"
            ></show-data>
          </template>
          <template #ELASTICACHE-EncryptionInTransit="{ item }">
            <show-data
              :data="item.ELASTICACHE.EncryptionInTransit"
              :FormatValueExceptions="true"
              :check="item.ELASTICACHE.EncryptionInTransit.Value === 100"
              unit="%"
              :score="getScore(item.ELASTICACHE, 'ELASTICACHE', 0)"
            ></show-data>
          </template>
          <template #KINESIS="{ item }">
            <show-score
              :score="getScore(item.KINESIS.EncryptionAtRest, 'ONE', 100)"
              :toggle="toggle.KINESIS"
              @show-hide="toggleRows('KINESIS')"
            ></show-score>
          </template>
          <template #KINESIS-EncryptionAtRest="{ item }">
            <show-data
              :data="item.KINESIS.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.KINESIS.EncryptionAtRest.Value === 100"
              unit="%"
              :score="getScore(item.KINESIS.EncryptionAtRest, 'ONE', 100)"
            ></show-data>
          </template>
          <template #KINESISFIREHOSE="{ item }">
            <show-score
              :score="
                getScore(item.KINESISFIREHOSE.EncryptionAtRest, 'ONE', 100)
              "
              :toggle="toggle.KINESISFIREHOSE"
              @show-hide="toggleRows('KINESISFIREHOSE')"
            ></show-score>
          </template>
          <template #KINESISFIREHOSE-EncryptionAtRest="{ item }">
            <show-data
              :data="item.KINESISFIREHOSE.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.KINESISFIREHOSE.EncryptionAtRest.Value === 100"
              unit="%"
              :score="
                getScore(item.KINESISFIREHOSE.EncryptionAtRest, 'ONE', 100)
              "
            ></show-data>
          </template>
          <template #SQS="{ item }">
            <show-score
              :score="getScore(item.SQS.EncryptionAtRest, 'ONE', 100)"
              :toggle="toggle.SQS"
              @show-hide="toggleRows('SQS')"
            ></show-score>
          </template>
          <template #SQS-EncryptionAtRest="{ item }">
            <show-data
              :data="item.SQS.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.SQS.EncryptionAtRest.Value === 100"
              unit="%"
              :score="getScore(item.SQS.EncryptionAtRest, 'ONE', 100)"
            ></show-data>
          </template>
          <template #BACKUP="{ item }">
            <show-score
              :score="getScore(item.BACKUP.EncryptionAtRest, 'ONE', 100)"
              :toggle="toggle.BACKUP"
              @show-hide="toggleRows('BACKUP')"
            ></show-score>
          </template>
          <template #BACKUP-EncryptionAtRest="{ item }">
            <show-data
              :data="item.BACKUP.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.BACKUP.EncryptionAtRest.Value === 100"
              unit="%"
              :score="getScore(item.BACKUP.EncryptionAtRest, 'ONE', 100)"
            ></show-data>
          </template>
          <template #XRAY="{ item }">
            <show-score
              :score="getScore(item.XRAY.Encryption, 'ONE', 100)"
              :toggle="toggle.XRAY"
              @show-hide="toggleRows('XRAY')"
            ></show-score>
          </template>
          <template #XRAY-Encryption="{ item }">
            <show-data
              :data="item.XRAY.Encryption"
              :FormatValueExceptions="true"
              :check="item.XRAY.Encryption.Value === 100"
              unit="%"
              :score="getScore(item.XRAY.Encryption, 'ONE', 100)"
            ></show-data>
          </template>
          <template #REDSHIFT="{ item }">
            <show-score
              :score="getScore(item.REDSHIFT, 'REDSHIFT', 100)"
              :toggle="toggle.REDSHIFT"
              @show-hide="toggleRows('REDSHIFT')"
            ></show-score>
          </template>
          <template #REDSHIFT-EncryptionAtRest="{ item }">
            <show-data
              :data="item.REDSHIFT.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.REDSHIFT.EncryptionAtRest.Value === 100"
              unit="%"
              :score="getScore(item.REDSHIFT, 'REDSHIFT', 100)"
            ></show-data>
          </template>
          <template #REDSHIFT-Public="{ item }">
            <show-data
              :data="item.REDSHIFT.Public"
              :FormatValueExceptions="true"
              :check="item.REDSHIFT.Public.Value === 0"
              :score="getScore(item.REDSHIFT, 'REDSHIFT', 100)"
            ></show-data>
          </template>
          <template #SAGEMAKER_NOTEBOOKINSTANCE="{ item }">
            <show-score
              :score="
                getScore(
                  item.SAGEMAKER_NOTEBOOKINSTANCE,
                  'SAGEMAKER_NOTEBOOKINSTANCE',
                  100
                )
              "
              :toggle="toggle.SAGEMAKER_NOTEBOOKINSTANCE"
              @show-hide="toggleRows('SAGEMAKER_NOTEBOOKINSTANCE')"
            ></show-score>
          </template>
          <template #SAGEMAKER_NOTEBOOKINSTANCE-DirectInternetAccess="{ item }">
            <show-data
              :data="item.SAGEMAKER_NOTEBOOKINSTANCE.DirectInternetAccess"
              :FormatValueExceptions="true"
              :check="
                item.SAGEMAKER_NOTEBOOKINSTANCE.DirectInternetAccess.Value === 0
              "
              unit="%"
              :score="
                getScore(
                  item.SAGEMAKER_NOTEBOOKINSTANCE,
                  'SAGEMAKER_NOTEBOOKINSTANCE',
                  100
                )
              "
            ></show-data>
          </template>
          <template #SAGEMAKER_NOTEBOOKINSTANCE-RootAccess="{ item }">
            <show-data
              :data="item.SAGEMAKER_NOTEBOOKINSTANCE.RootAccess"
              :FormatValueExceptions="true"
              :check="item.SAGEMAKER_NOTEBOOKINSTANCE.RootAccess.Value === 0"
              unit="%"
              :score="
                getScore(
                  item.SAGEMAKER_NOTEBOOKINSTANCE,
                  'SAGEMAKER_NOTEBOOKINSTANCE',
                  100
                )
              "
            ></show-data>
          </template>
          <template #SAGEMAKER_TRAININGJOB="{ item }">
            <show-score
              :score="
                getScore(item.SAGEMAKER_TRAININGJOB, 'SAGEMAKER_TRAININGJOB', 0)
              "
              :toggle="toggle.SAGEMAKER_TRAININGJOB"
              @show-hide="toggleRows('SAGEMAKER_TRAININGJOB')"
            ></show-score>
          </template>
          <template
            #SAGEMAKER_TRAININGJOB-EnableInterContainerTrafficEncryption="{
              item,
            }"
          >
            <show-data
              :data="
                item.SAGEMAKER_TRAININGJOB.EnableInterContainerTrafficEncryption
              "
              :FormatValueExceptions="true"
              :check="
                item.SAGEMAKER_TRAININGJOB.EnableInterContainerTrafficEncryption
                  .Value === 100
              "
              unit="%"
              :score="
                getScore(item.SAGEMAKER_TRAININGJOB, 'SAGEMAKER_TRAININGJOB', 0)
              "
            ></show-data>
          </template>
          <template #SAGEMAKER_TRAININGJOB-NetworkIsolation="{ item }">
            <show-data
              :data="item.SAGEMAKER_TRAININGJOB.NetworkIsolation"
              :FormatValueExceptions="true"
              :check="item.SAGEMAKER_TRAININGJOB.NetworkIsolation.Value === 100"
              unit="%"
              :score="
                getScore(item.SAGEMAKER_TRAININGJOB, 'SAGEMAKER_TRAININGJOB', 0)
              "
            ></show-data>
          </template>
          <template #WORKSPACES="{ item }">
            <show-score
              :score="getScore(item.WORKSPACES, 'WORKSPACES', 0)"
              :toggle="toggle.WORKSPACES"
              @show-hide="toggleRows('WORKSPACES')"
            >
            </show-score>
          </template>
          <template #WORKSPACES-EncryptionAtRest="{ item }">
            <show-data
              :data="item.WORKSPACES.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.WORKSPACES.EncryptionAtRest.Value === 100"
              unit="%"
              :score="getScore(item.WORKSPACES, 'WORKSPACES', 0)"
            ></show-data>
          </template>
          <template #WORKSPACES-EncryptionRoot="{ item }">
            <show-data
              :data="item.WORKSPACES.EncryptionRoot"
              :FormatValueExceptions="true"
              :check="item.WORKSPACES.EncryptionRoot.Value === 100"
              unit="%"
              :score="getScore(item.WORKSPACES, 'WORKSPACES', 0)"
            ></show-data>
          </template>
          <template #WORKSPACES-EncryptionUser="{ item }">
            <show-data
              :data="item.WORKSPACES.EncryptionUser"
              :FormatValueExceptions="true"
              :check="item.WORKSPACES.EncryptionUser.Value === 100"
              unit="%"
              :score="getScore(item.WORKSPACES, 'WORKSPACES', 0)"
            ></show-data>
          </template>
          <template #GLUE="{ item }">
            <show-score
              :score="getScore(item.GLUE, 'GLUE', 0)"
              :toggle="toggle.GLUE"
              @show-hide="toggleRows('GLUE')"
            ></show-score>
          </template>
          <template #GLUE-EncryptionAtRest="{ item }">
            <show-data
              :data="item.GLUE.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.GLUE.EncryptionAtRest.Value === 100"
              unit="%"
              :score="getScore(item.GLUE, 'GLUE', 0)"
            ></show-data>
          </template>
          <template #GLUE-EncryptionInTransit="{ item }">
            <show-data
              :data="item.GLUE.EncryptionInTransit"
              :FormatValueExceptions="true"
              :check="item.GLUE.EncryptionInTransit.Value === 100"
              unit="%"
              :score="getScore(item.GLUE, 'GLUE', 0)"
            ></show-data>
          </template>
          <template #GLUE-ConnectionPasswordEncryption="{ item }">
            <show-data
              :data="item.GLUE.ConnectionPasswordEncryption"
              :FormatValueExceptions="true"
              :check="item.GLUE.ConnectionPasswordEncryption.Value === 100"
              unit="%"
              :score="getScore(item.GLUE, 'GLUE', 0)"
            ></show-data>
          </template>
          <template #STORAGEGATEWAY="{ item }">
            <show-score
              :score="getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)"
              :toggle="toggle.STORAGEGATEWAY"
              @show-hide="toggleRows('STORAGEGATEWAY')"
            ></show-score>
          </template>
          <template #STORAGEGATEWAY-VOL-EncryptionAtRest="{ item }">
            <show-data
              :data="item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.EncryptionAtRest
                  .Value === 100
              "
              unit="%"
              :score="getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)"
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-TAPE-EncryptionAtRest="{ item }">
            <show-data
              :data="item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.EncryptionAtRest"
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.EncryptionAtRest
                  .Value === 100
              "
              unit="%"
              :score="getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)"
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-SHARE-EncryptionAtRest="{ item }">
            <show-data
              :data="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.EncryptionAtRest
              "
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.EncryptionAtRest
                  .Value === 100
              "
              unit="%"
              :score="getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)"
            >
            </show-data>
          </template>
          <template #STORAGEGATEWAY-SHARE-EncryptionInTransit="{ item }">
            <show-data
              :data="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.EncryptionInTransit
              "
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.EncryptionInTransit
                  .Value === 100
              "
              unit="%"
              :score="getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)"
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-SHARE-GuestPassword="{ item }">
            <show-data
              :data="item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.GuestPassword"
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.GuestPassword
                  .Value === 100
              "
              unit="%"
              :score="getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY', 0)"
            ></show-data>
          </template>
          <template #VPC="{ item }">
            <show-score
              :score="getScore(item.VPC, 'VPC', 100)"
              :toggle="toggle.VPC"
              @show-hide="toggleRows('VPC')"
            ></show-score>
          </template>
          <template #VPC-Clean="{ item }">
            <show-data
              :data="item.VPC.Clean"
              :FormatValueExceptions="true"
              :check="item.VPC.Clean.Value === 100"
              unit="%"
              :score="getScore(item.VPC, 'VPC', 100)"
            ></show-data>
          </template>
          <template #VPC-CompliantIP="{ item }">
            <show-data
              :data="item.VPC.CompliantIP"
              :FormatValueExceptions="true"
              :check="item.VPC.CompliantIP.Value === 100"
              unit="%"
              :score="getScore(item.VPC, 'VPC', 100)"
            ></show-data>
          </template>
          <template #NETWORKFIREWALL="{ item }">
            <show-score
              :score="getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL', 100)"
              :toggle="toggle.NETWORKFIREWALL"
              @show-hide="toggleRows('NETWORKFIREWALL')"
            ></show-score>
          </template>
          <template #NETWORKFIREWALL-Logging="{ item }">
            <show-data
              :data="item.NETWORKFIREWALL.Logging"
              :FormatValueExceptions="true"
              :check="item.NETWORKFIREWALL.Logging.Value === 100"
              unit="%"
              :score="getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL', 100)"
            ></show-data>
          </template>
          <template #NETWORKFIREWALL-DenyAnyAny="{ item }">
            <show-data
              :data="item.NETWORKFIREWALL.DenyAnyAny"
              :FormatValueExceptions="true"
              :check="item.NETWORKFIREWALL.DenyAnyAny.Value === 100"
              unit="%"
              :score="getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL', 100)"
            ></show-data>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { getBaseScore } from "./components/base/GetScore.js";
import ShowScore from "./components/base/ShowScore.vue";
import ShowData from "./components/base/ShowData.vue";
import { insertDetailsFields } from "./components/helpers.js";
import { defaultFields, detailsFields } from "./components/base/Fields.js";
import { defaultTips } from "./components/base/Tips.js";
import SecurityHelp from "./SecurityHelp.vue";
export default {
  components: {
    ShowScore,
    ShowData,
    SecurityHelp,
  },
  data() {
    return {
      workInProgress: false,
      toggle: {},
      results: [],
      details: [],
      header: [],
      detailsFields: {},
      fields: [],
      availableAccounts: [],
      isLoading: false,
      lastGenerated: "",
      tips: [],
      selectedtips: "",
      selectedColumn: "",
    };
  },
  computed: {
    computedItems() {
      return this.results.map((item, id) => {
        return {
          ...item,
          id,
        };
      });
    },
  },
  async created() {
    var accessToken = await this.$auth.getAccessToken();

    if (
      this.$store.getters["portalParametersModule/kpisWorkInProgress"] ===
      undefined
    ) {
      await this.$store.dispatch("portalParametersModule/loadWipParameters", {
        refresh: true,
        accessToken: accessToken,
      });
    }
    this.workInProgress =
      this.$store.getters["portalParametersModule/kpisWorkInProgress"];
    if (!this.workInProgress) {
      this.loadKpireport();
    }
  },
  methods: {
    rowClicked(_1, _2, columnname) {
      this.selectedColumn = columnname;
      this.selectedtips = this.tips[columnname.replace(/-/g, "")];
    },
    linkToAccounts(item) {
      for (let k in this.availableAccounts) {
        const row = this.availableAccounts[k];
        if (
          // row.BE === item.BE &&
          // row.BU === item.BU &&
          row.OrgId === item.OrgId
        ) {
          return true;
        }
      }
    },
    getScore(element, header, valueok) {
      return getBaseScore(element, header, valueok);
    },

    toggleRows(detail) {
      this.fields = insertDetailsFields(
        detail,
        this.fields,
        this.toggle[detail],
        this.detailsFields[detail]
      );
      this.toggle[detail] = !this.toggle[detail];
    },
    async loadKpireport(refresh = false) {
      this.isLoading = true;
      const table = document.querySelector(".table");
      var accessToken = await this.$auth.getAccessToken();
      await this.$store.dispatch("kpiReportModule/loadKpiReport", {
        refresh: refresh,
        accessToken: accessToken,
      });
      this.isLoading = false;
      table.style.display = "block";

      const data = this.$store.getters["kpiReportModule/kpiGlobalReport"];
      const accountsData =
        this.$store.getters["kpiReportModule/kpiDetailedReport"];
      this.lastGenerated =
        this.$store.getters["kpiReportModule/kpiDateGenerated"];

      let retval = Object.keys(data[0]);
      retval = Array.from(new Set(retval));

      for (let i in this.headers) {
        this.toggle[this.headers[i]] = false;
      }

      this.fields = defaultFields;
      this.tips = defaultTips;
      this.detailsFields = detailsFields;
      this.results = Array.from(new Set(data));
      this.headers = retval.sort();

      for (let k in accountsData) {
        this.availableAccounts.push({
          // BE: accountsData[k].BE,
          // BU: accountsData[k].BU,
          OrgId: accountsData[k].OrgId,
        });
      }
    },
  },
};
</script>
